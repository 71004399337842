<template>
  <div>
    <div class="banner_bg">
      <div class="container_wrap">
        <div class="banner">
          <el-carousel trigger="click" height="400px" v-if="advertList && advertList.length !== 0">
            <el-carousel-item v-for="item in advertList" :key="item.id">
              <img :src="item.image" v-if="item.href === ''">
              <a :href="item.href" v-if="item.mode===1" target="_blank" @click="updateAdvert(item.id)"><img :src="item.image"></a>
              <a :href="item.href" v-if="item.mode===2" target="" @click="updateAdvert(item.id)"><img :src="item.image"></a>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="intro">
          <h2>企业内训</h2>
          <p>企业内训是推动企业不断发展的重要手段之一。我们以整合培训产业价值体系为宗旨，以客户需求为导向，期许通过整合学习资源，与各专家及专业机构携手，为企业提供专业、丰富、便捷、高效的全方位培训服务，共同搭建企业的知识管理平台，提升企业核心竞争力与促进学习产业发展。</p>
          <div class="text-center">
            <el-button type="primary" @click="anchorLinkTo('applyFormAll')">立即申请</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="area_service">
      <div class="container_wrap">
        <h1>服务项目<span>多元化教学形式，满足企业差异化培训需求</span></h1>
        <el-row :gutter="100">
          <el-col :span="6">
            <div class="icon orange">
              <i class="iconfont icon-icon-102"></i>
            </div>
            <h3>企业定制培训</h3>
            <p>定制企业培训课程，提供讲师、助教、教学资料、实验资源等教学服务，多种教学模式任意组合。</p>
          </el-col>
          <el-col :span="6">
            <div class="icon green">
              <i class="iconfont icon-icon-113"></i>
            </div>
            <h3>认证培训</h3>
            <p>提供阿里云、腾讯云等多家知名厂商的认证培训课程及认证考试服务。</p>
          </el-col>
          <el-col :span="6">
            <div class="icon red">
              <i class="iconfont icon-icon-122"></i>
            </div>
            <h3>直播培训</h3>
            <p>为企业开通专属直播课堂，既支持上万人同时在线的大型培训，也满足强互动需求的小规模培训。</p>
          </el-col>
          <el-col :span="6">
            <div class="icon blue">
              <i class="iconfont icon-icon-131"></i>
            </div>
            <h3>视频培训</h3>
            <p>丰富的在线视频课程库，涵盖前沿热门技术，针对性提供技术提升方案。</p>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="area_system">
      <h1>知识体系<span>课程全面覆盖 ICT 技术领域，帮助团队全方位系统化提升技术能力</span></h1>
    </div>
    <div class="area_advantage">
      <div class="container_wrap">
        <h1>核心优势<span>高标准，深技术，严保障，用心服务好每一位培训人员</span></h1>
        <el-row :gutter="28">
          <el-col :span="6">
            <div class="advantage_item skyblue">
              <div class="card">
                <div class="icon">
                  <i class="iconfont icon-icon-141"></i>
                </div>
                <h3>优质的培训课程</h3>
                <p>与众多企业深度合作，实时跟进前沿热门技术。由专业的讲师和课程研发团队共同定制培训方案，严格把控培训课程质量。</p>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="advantage_item red">
              <div class="card">
                <div class="icon">
                  <i class="iconfont icon-icon-15"></i>
                </div>
                <h3>专业的师资团队</h3>
                <p>大厂技术大牛+硬核讲师，为教学质量保驾护航。培训讲师来自国内外知名企业，均有多年企业实战经验和教学经验。</p>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="advantage_item blue">
              <div class="card">
                <div class="icon">
                  <i class="iconfont icon-icon-16"></i>
                </div>
                <h3>高效的学习平台</h3>
                <p>提供免费私有云/公有云学习平台，实操环节学员独享快速、高效、灵活的实验实训环境，还原最真实的企业生产环境。</p>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="advantage_item purple">
              <div class="card">
                <div class="icon">
                  <i class="iconfont icon-icon-17"></i>
                </div>
                <h3>贴心的助学服务</h3>
                <p>由课程的核心讲师带队，助教老师一对一在线批改作业、讲解答疑，保证学习过程中的及时交互。</p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="area_train">
      <h1>培训流程<span>规范化流程，全面跟踪落实企业培训</span></h1>
      <div class="container_wrap">
        <el-row :gutter="20">
          <el-col :span="5">
            <div class="train_item">
              <div class="top">STEP 1</div>
              <h3>明确培训需求</h3>
              <p>与客户代表及专家顾问深度沟通，明确培训需求及标准。</p>
              <img :src="require('assets/img/bg/nx_box.png')" />
            </div>
          </el-col>
          <el-col :span="5">
            <div class="train_item">
              <div class="top">STEP 2</div>
              <h3>制定培训方案</h3>
              <p>根据企业的培训需求，量身定制培训方案。</p>
              <img :src="require('assets/img/bg/nx_box.png')" />
            </div>
          </el-col>
          <el-col :span="5">
            <div class="train_item">
              <div class="top">STEP 3</div>
              <h3>签订培训协议</h3>
              <p>确认企业培训实施细节，签署培训协议。</p>
              <img :src="require('assets/img/bg/nx_box.png')" />
            </div>
          </el-col>
          <el-col :span="5">
            <div class="train_item">
              <div class="top">STEP 4</div>
              <h3>实施培训计划</h3>
              <p>按照培训协议约定，实施培训。专属客服全程跟踪培训，保证课程质量。</p>
              <img :src="require('assets/img/bg/nx_box.png')" />
            </div>
          </el-col>
          <el-col :span="5">
            <div class="train_item">
              <div class="top">STEP 5</div>
              <h3>评估培训效果</h3>
              <p>培训结束后提交学员反馈，评估课程培训效果，协助企业对项目复盘。</p>
              <img :src="require('assets/img/bg/nx_box.png')" />
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="area_apply">
      <h1>立即申请<span>为您定制企业专属培训方案</span></h1>
      <div class="container_wrap">
        <el-row>
          <el-col :span="12">
            <div class="apply_form" ref="applyFormAll">
              <div class="head">请填写资料</div>
              <div class="content">
                <el-form :model="applyModal.form" :rules="applyModal.rules" label-width="80px" ref="applyForm">
                  <el-form-item label="姓名" prop="applyer_name">
                    <el-input v-model="applyModal.form.applyer_name"></el-input>
                  </el-form-item>
                  <el-form-item label="电话" prop="cellphone">
                    <el-input v-model="applyModal.form.cellphone"></el-input>
                  </el-form-item>
                  <el-form-item label="单位" prop="company">
                    <el-input v-model="applyModal.form.company"></el-input>
                  </el-form-item>
                  <el-form-item label="需求" prop="require">
                    <el-input type="textarea" v-model="applyModal.form.require" rows="3" resize="none"></el-input>
                  </el-form-item>
                  <el-form-item label="验证码" prop="input_code">
                    <el-row :gutter="10">
                      <el-col :span="12">
                        <el-input v-model="applyModal.form.input_code"></el-input>
                      </el-col>
                      <el-col :span="12">
                        <SIdentify @getCode="getCode" ref="s-identify"></SIdentify>
                      </el-col>
                    </el-row>
                  </el-form-item>

                  <el-form-item>
                    <el-button type="primary" @click="submitForm('applyForm')">立即申请</el-button>
                    <span class="tip">申请信息提交后，我们会第一时间主动联系您，请耐心等候。</span>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="apply_list" v-if="showNameList">
              <h4>他们已经申请</h4>
              <div class="name_list" @mouseenter="stopScroll" @mouseleave="startScroll">
                <ul :class="{'animate': isScroll}" ref="nameList">
                  <li v-for="item in nameList" >
                    <span>{{ (item.applyer_name) }}</span>
                    <span>{{ (item.cellphone) }}</span>
                    <span class="text_ellipsis">{{ (item.company) }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
  import { checkTrainPhone } from 'utils/common.js'
  import { createTrain, getTrain } from 'api/training/list'
  import { showMessage } from 'api/base/message'
  import SIdentify from 'components/common/SIdentify'
  import { getAdvertList, updateHitNum } from 'api/advert/admin'
  export default {
    components: {
      SIdentify
    },
    data() {
      return {
          showNameList: true,
        nameListNeedScroll: false,
        isScroll: false,
        scrollHandler:'',
        nameList:[],
        advertList: [],
        code:'',
        applyModal: {
          visible: false,
          form: {
            applyer_name: '',
            cellphone: '',
            company: '',
            require: '',
            input_code:''
          },
          rules: {
            applyer_name: [
              { required: true, message: '请输入姓名', trigger: 'change' },
              { max: 15, message: '名称请保持15个字符以内',trigger: 'change'}
            ],
            cellphone: [
              { required: true, validator: checkTrainPhone, trigger: 'blur' }
            ],
            company: [
              { required: true, message: '请输入单位名称', trigger: 'change' },
              { max: 15, message: '单位请保持20个字符以内',trigger: 'change'}
            ],
            require: [
              { required: true, message: '请输入需求', trigger: 'change' },
              { max: 100, message: '需求请保持100个字符以内',trigger: 'change'}
            ],
            input_code: [
              { required: true, message: '请输入验证码', trigger: 'change' }
            ],
          }
        },
      }
    },
    mounted() {
      this.getAdvertList();
      this.getTrainList();
    },
    methods: {
      getCode(val) {
        this.code = val
        console.log(val)
      },
      submitForm(formName) {
          this.$refs['s-identify'].refreshCode();
          this.$refs[formName].validate((valid) => {
            if (valid) {
              var values = this.applyModal.form
              if (values.input_code != this.code) {
                showMessage("验证码有误请重新输入(区分大小写)！",'error')
              }else {
                createTrain(values).then(() => {
                  this.$refs.applyForm.resetFields()
                  this.showNameList = false;
                  this.getTrainList()
                  setTimeout(()=>{
                      this.showNameList = true;
                  }, 500)
                  showMessage("申请成功！",'success')
                })
              }
            } else {
              showMessage("所填内容有误(请按照要求填写)！",'error');
              return false;
            }
          });
      },
      getTrainList() {
        let filters = {
          'limit': 20,
          'offset': 0
        }
        getTrain(filters).then((res) =>{
          this.nameList = res.data;
          this.$nextTick(()=>{
              if(this.$refs.nameList.offsetHeight > 342) {
                this.nameListNeedScroll = true;
                this.startScroll();
              }
          });
        })
      },
      getAdvertList() {
        const that = this
        let filters = {
          location: 1,
          state:  1,
          order: true
        }
        getAdvertList(filters).then(res => {
          that.advertList = res.data
        })
      },
      updateAdvert(id) {
        let data = new FormData();
        data.append('up_hit_num', '1');
        updateHitNum(id, data).then(() => {
        })
      },
      nameScroll() {
        this.isScroll = true;
        setTimeout(()=>{
           this.nameList.push(this.nameList[0]);
           this.nameList.shift();
           this.isScroll = false;
        },500)
      },
      startScroll() {
        if(this.nameListNeedScroll) {
          this.scrollHandler = setInterval(this.nameScroll,1000);
        }
      },
      stopScroll() {
        if(this.nameListNeedScroll) {
          clearInterval(this.scrollHandler);
        }
      },
      anchorLinkTo (ref) {
        window.scrollTo({
            top: this.$refs[ref].getBoundingClientRect().top,
            behavior: "smooth"
        });
      },
    },
//    computed: {
//      cellphoneFilters() {
//        return function(value) {
//          value = value.toString()
//          if (value.length == 11){
//            let cell_data = value.substring(0,2)+"*****"+value.substring(value.length-2)
//            return cell_data
//          }else {
//            let cell_phone = value.substring(0,5)+"*****"
//            return cell_phone
//          }
//        };
//      },
//      applyerNameFilters() {
//        return function (value) {
//          value = value.toString()
//          let name_data = value.substring(0,1)+"*****"
//          return name_data
//        }
//      },
//      companyFiltes() {
//          return function (value) {
//            value = value.toString()
//            if (value.length > 4) {
//                let company_data = "*****"+value.substring(value.length-4)
//                return company_data
//            }else {
//                let company_data = "****"
//                return company_data
//            }
//          }
//      }
//    }
  }
</script>

<style lang="scss" scoped>
  @import 'assets/font/font.scss';
  h1 {
    font-size:34px;
    color:#333;
    margin-top:0;
    margin-bottom:50px;
    text-align:center;
    font-family: 'font-pingfang-bold';
    > span {
      display:block;
      font-size:18px;
      color:#999;
      margin-top:20px;
    }
  }
  .banner_bg {
    margin:-20px -20px 0 -20px;
    height:380px;
    background:#0080bb url('~@/assets/img/bg/nx_banner.png') no-repeat bottom center;
    .container_wrap {
      height:480px;
      display:flex;
      padding-top:80px;
      .banner {
        width:860px;
        background:#fff;
        :deep(.el-carousel) {
          img {
            width:100%;
            height:100%;
          }
        }
      }
      .intro {
        flex:1;
        background:#fff;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.1);
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
        padding:20px;
        h2 {
          color:#4aa8f2;
          margin-top:0;
          padding-bottom:15px;
          border-bottom:2px solid #f1f5f8;
        }
        p {
          line-height:2;
          margin-top:0;
          margin-bottom:40px;
        }
        .el-button {
          padding:15px 80px;
          background:#4aa8f2;
          &:hover {
            background:#34A2F8;
          }
        }
      }
    }
  }
  .area_service {
    background:#f5f7fb;
    margin-left:-20px;
    margin-right:-20px;
    .container_wrap {
      padding-top:170px;
      padding-bottom:50px;
      .el-row {
        text-align: center;
        .icon {
          width:66px;
          height:66px;
          border-radius:50%;
          text-align: center;
          line-height: 66px;
          margin:0 auto 25px;
          i {
            color:#fff;
            font-size: 36px;
          }
          &.orange {
            background:#fda83b;
          }
          &.green {
            background:#78be78;
          }
          &.red {
            background:#f15a51;
          }
          &.blue {
            background:#4aa8f2;
          }
        }
        h3 {
          font-size: 16px;
          color:#333;
          margin-top:0;
          margin-bottom:20px;
        }
        p {
          font-size:12px;
          line-height:2;
          margin:0;
        }
      }
    }
  }
  .area_system {
    height:620px;
    margin-left:-20px;
    margin-right:-20px;
    background:#010505 url('~@/assets/img/pic/nx.png') no-repeat top center;
    h1 {
      padding-top:65px;
      color:#fff;
    }
  }
  .area_advantage {
    padding:80px 0;
    margin-left:-20px;
    margin-right:-20px;
    .advantage_item {
      padding:15px 0;
      position:relative;
      .card {
        border-radius:8px;
        height:300px;
        padding-top:30px;
        text-align:center;
        position:relative;
        overflow:hidden;
        .icon {
          width:80px;
          height:80px;
          border-radius:50%;
          text-align:center;
          line-height:80px;
          background:rgba(255,255,255,0.4);
          margin:0 auto 25px;
          > i {
            color:#fff;
            font-size:42px;
          }
        }
        h3 {
          color:#fff;
          margin-top:0;
          margin-bottom:20px;
        }
        p {
          font-size:12px;
          padding:0 30px;
          color:#fff;
          line-height:2;
        }
        &:before {
          content: '';
          width:280px;
          height:300px;
          background:url('~@/assets/img/bg/nx_light.png') no-repeat top center;
          position:absolute;
          left:0;
          right:0;
          top:0;
          bottom:0;
          -webkit-transition: all .2s ease;
          transition: all .2s ease;
        }
        &:hover {
          &:before {
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
          }
        }
      }
      &:before {
        content: '';
        position:absolute;
        left:20px;
        top:0;
        right:20px;
        bottom:0;
        border-radius:8px;
        z-index:-1;
      }
      &.skyblue {
        .card {
          background:#4aa8f2;
        }
        &:before {
          background:#e5f3ff;
        }
      }
      &.red {
        .card {
          background: #f15a51;
        }
        &:before {
          background:#feeeed;
        }
      }
      &.blue {
        .card {
          background: #408cff;
        }
        &:before {
          background:#ebf3ff;
        }
      }
      &.purple {
        .card {
          background: #7453bc;
        }
        &:before {
          background:#f1edf8;
        }
      }
    }
  }
  .area_train {
    padding:80px 0;
    background:#f5f7fb;
    margin-left:-20px;
    margin-right:-20px;
    .train_item {
      height:260px;
      border-radius:8px;
      background:#fff;
      padding:50px 18px 0 18px;
      position:relative;
      text-align:center;
      -webkit-box-shadow:0 0 10px rgba(0,0,0,0.1);
      box-shadow:0 0 10px rgba(0,0,0,0.1);
      .top {
        color:#fff;
        padding:8px 45px 8px 20px;
        border-radius: 20px 20px 20px 0;
        background:#4aa8f2;
        position:absolute;
        top:-16px;
        left:0;
      }
      h3 {
        color:#333;
        margin-top:0;
        margin-bottom: 20px;
      }
      p {
        line-height:2;
        margin:0;
      }
      > img {
        position:absolute;
        bottom:-1px;
        left:0;
        width:100%;
      }
    }
  }
  .area_apply {
    padding-top:80px;
    padding-bottom:80px;
    .apply_form {
      border-radius:4px;
      overflow:hidden;
      background:#fff;
      -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.1);
      box-shadow: 0 0 10px rgba(0,0,0,0.1);
      position:relative;
      z-index:1;
      .head {
        padding:22px 40px;
        background:#4aa8f2;
        color:#fff;
      }
      .content {
        padding:30px 30px 30px 0;
        .tip {
          font-size:12px;
          margin-left:15px;
          color:#999;
        }
      }
    }
    .apply_list {
      background:#fff;
      -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.1);
      box-shadow: 5px 0 10px rgba(0,0,0,0.1);
      border-radius:0 4px 4px 0;
      padding:15px;
      margin-top:80px;
      h4 {
        margin:0;
        padding-bottom:15px;
        border-bottom:1px solid #f0f0f0;
      }
      .name_list {
        height:342px;
        overflow:hidden;
        position:relative;
        ul {
          position:absolute;
          top:0;
          left:0;
          right:0;
          li {
            padding:20px 15px;
            border-bottom:1px solid #f0f0f0;
            display:flex;
            span {
              width:33.333%;
            }
          }
          &.animate {
            -webkit-transition: all 0.5s;
            transition: all 0.5s;
            top: -57px;
          }
        }
      }
    }
  }
  .el-col-5 {
    max-width: 20%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
  }
</style>
