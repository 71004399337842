import { fetch, post, del } from 'api/base/axios'

const URL = {
  trainListUrl: '/training/get_train_list/',
  trainUrl: '/training/get_train/',
  updateTrainUrl: '/training/update_train/',
  createTrainUrl: '/training/create_train/',
  delTrainUrl: '/training/del_train/',
  delNumTraining: '/user/del_numenter/',
}


export function getTrainList (data) {
  return fetch(URL.trainListUrl, data)
}

export function getTrain (data) {
  return fetch(URL.trainUrl, data)
}

export function updateTrain (train_id, form) {
  return post(URL.updateTrainUrl + train_id , form)
}

export function createTrain (form) {
  return post(URL.createTrainUrl, form)
}

export function delTrain (form) {
  return fetch(URL.delTrainUrl, form)
}

export function delNumEnter (form) {
  return del(URL.delNumEnter, { data: form })
}
